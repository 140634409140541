var pathPrefix = "";

/**
 * Normalize path. Convert /foo/bar/../baz into /foo/baz
 * @param {string} path with "dots" (.. and .)
 * @returns normalized path without "dots"
 */
function normalize(path) {
  if (path == "/") return path;
  var components = path.split("/");
  if (components.shift() != "") {
    throw new Error('Incorrect path: "' + path + '" is not an absolute path. It should start with "/".');
  }
  if (components[components.length - 1] == "") {
    throw new Error('Incorrect path: "' + path + '" ending with "/".');
  }
  var normalized = [];

  components.forEach((component) => {
    switch (component) {
      case "":
        throw new Error('Incorrect path: "' + path + '" contains empty components.');

      case ".":
        break;

      case "..":
        if (normalized.length > 0) {
          normalized.pop();
        } else {
          throw new Error('Incorrect path: "' + path + "\" can't be normalized!");
        }
        break;

      default:
        normalized.push(component);
    }
  });
  return "/" + normalized.join("/");
}

/**
 * Fetch translation using path
 * @param bundles Fluent bundles
 * @param path absolute or relative path to the translation
 * @param data data for substitution
 * @return translation or the path (if translation was not found)
 */
export function translate(bundles, path, data = null) {
  if (path == "") return "";
  path = getFullPath(String(path));

  let bundlePath = path.split("/").slice(0, -1).join("/"),
    messageId = path.split("/").pop();

  if (bundlePath in bundles) {
    let bundle = bundles[bundlePath];
    if (bundle.hasMessage(messageId)) {
      return bundle.formatPattern(bundle.getMessage(messageId).value, data, null);
    }
  }

  return path;
}

/**
 * Get full path
 */
export function getFullPath(path) {
  path = String(path);
  if (path.charAt(0) == "/") return normalize(path);
  return normalize(pathPrefix + "/" + path);
}

/**
 * Use path prefix
 * @param path new path prefix
 */
export function usePath(path) {
  path = String(path);
  if (path == "") return;
  path = path.replace(/\/+$/g, "");
  if (path.charAt(0) == "/") {
    pathPrefix = normalize(path);
  } else {
    pathPrefix = normalize(pathPrefix + "/" + path);
  }
  if (pathPrefix == "/") pathPrefix = "";
}

/**
 * Vue l10n plugin. Supports .ftl files (Fluent syntax by Mozilla)
 * @see https://www.projectfluent.org/
 * @param {Vue} Vue
 */
function plugin(Vue) {
  Vue.mixin({
    methods: {
      t(path, data = null) {
        return translate(this.$store.state.bundles, path, data);
      },
      tt(path) {
        usePath(path);
      },
    },
  });
}

export default plugin;
