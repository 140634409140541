var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row gutt-2 gutt-md-2" }, [
    _c("div", { staticClass: "col-12 col-md-6" }, [
      _c("div", { staticClass: "row gutt-2" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "button",
            {
              staticClass: "btn-default p-1",
              on: { click: _vm.authWithGoogle },
            },
            [
              _c("i", { staticClass: "i google" }),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.t("/login/login-with-google")) +
                  "\n        "
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 col-md-6" }, [
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.signupWithEmail.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "row gutt-2" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showPasswordRequirements,
                    expression: "showPasswordRequirements",
                  },
                ],
                staticClass: "col-12",
              },
              [
                _c("div", { staticClass: "alert-info" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn-dismiss",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.showPasswordRequirements = false
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-times" })]
                  ),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.t("/forms/password-requirements")) +
                      "\n          "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.name.value,
                    expression: "name.value",
                    modifiers: { trim: true },
                  },
                ],
                class: [
                  "text-field",
                  { "is-wrong": _vm.name.error && _vm.name.showError },
                ],
                attrs: { type: "text", placeholder: _vm.t("/forms/name") },
                domProps: { value: _vm.name.value },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.name, "value", $event.target.value.trim())
                    },
                    function ($event) {
                      _vm.name.showError = false
                    },
                  ],
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form-error" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.name.error) + "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.email.value,
                    expression: "email.value",
                    modifiers: { trim: true },
                  },
                ],
                class: [
                  "text-field",
                  { "is-wrong": _vm.email.error && _vm.email.showError },
                ],
                attrs: { type: "text", placeholder: _vm.t("/forms/email") },
                domProps: { value: _vm.email.value },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.email, "value", $event.target.value.trim())
                    },
                    function ($event) {
                      _vm.email.showError = false
                    },
                  ],
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form-error" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.email.error) + "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password.value,
                    expression: "password.value",
                  },
                ],
                class: [
                  "text-field",
                  { "is-wrong": _vm.password.error && _vm.password.showError },
                ],
                attrs: {
                  type: "password",
                  placeholder: _vm.t("/forms/password"),
                },
                domProps: { value: _vm.password.value },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.password, "value", $event.target.value)
                    },
                    function ($event) {
                      _vm.password.showError =
                        _vm.passwordConfirm.showError = false
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form-error" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.password.error) + "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.passwordConfirm.value,
                    expression: "passwordConfirm.value",
                  },
                ],
                class: [
                  "text-field",
                  {
                    "is-wrong":
                      _vm.passwordConfirm.error &&
                      _vm.passwordConfirm.showError,
                  },
                ],
                attrs: {
                  type: "password",
                  placeholder: _vm.t("/forms/password-confirm"),
                },
                domProps: { value: _vm.passwordConfirm.value },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.passwordConfirm,
                        "value",
                        $event.target.value
                      )
                    },
                    function ($event) {
                      _vm.passwordConfirm.showError = false
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form-error" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.passwordConfirm.error) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c(
                "label",
                {
                  class: [
                    "checkbox",
                    { "is-wrong": _vm.iAgree.error && _vm.iAgree.showError },
                  ],
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.iAgree.value,
                        expression: "iAgree.value",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.iAgree.value)
                        ? _vm._i(_vm.iAgree.value, null) > -1
                        : _vm.iAgree.value,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.iAgree.value,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(_vm.iAgree, "value", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.iAgree,
                                  "value",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.iAgree, "value", $$c)
                          }
                        },
                        function ($event) {
                          _vm.iAgree.showError = false
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c("span"),
                  _vm._v(" "),
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.t("/forms/i-agree")) },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-error" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.iAgree.error) + "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row gutt-2" }, [
                _c("div", { staticClass: "col l" }, [
                  _c(
                    "button",
                    {
                      class: ["btn-primary", { busy: _vm.busy }],
                      attrs: { type: "submit" },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.t("/signup/submit")) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col r" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn-default",
                      attrs: { type: "button" },
                      on: { click: _vm.cancel },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.t("/forms/cancel")) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }