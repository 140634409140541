<template>
  <div id="wrap">
    <Header />
    <StaticPage v-if="error404" />
    <Unauthorized v-else-if="error403" />
    <RouterView v-else-if="routingDone" />
    <Footer />
    <Notifications />
    <template v-if="!authorized">
      <Signup />
      <Login v-if="!error403" />
    </template>
    <template v-if="!error404 && !error403 && isEventEditor">
      <TimeSettings />
      <SpecialInviteeForm />
    </template>
    <Redirect v-if="showRedirectMessage" />
    <CookieDisclaimer />
  </div>
</template>

<script>
import Header from "../utils/components/Header.vue";
import Footer from "../utils/components/Footer.vue";
import Signup from "../utils/components/Auth/Signup.vue";
import Login from "../utils/components/Auth/Login.vue";
import Notifications from "../utils/components/Notifications.vue";
import Redirect from "../utils/components/Redirect.vue";
import CookieDisclaimer from "../utils/components/CookieDisclaimer.vue";
import {apiCall} from "../utils/api";
import {trialRequested} from "../utils/helpers";

const StaticPage = () => import("./StaticPage.vue");
const Unauthorized = () => import("./Unauthorized.vue");
const TimeSettings = () => import("./EventEditor/TimeSettings.vue");
const SpecialInviteeForm = () => import("./EventEditor/SpecialInviteeForm.vue");

export default {
  components: {
    Header,
    Footer,
    Signup,
    Login,
    Notifications,
    TimeSettings,
    SpecialInviteeForm,
    StaticPage,
    Unauthorized,
    Redirect,
    CookieDisclaimer,
  },
  data() {
    return {
      showRedirectMessage: false,
    };
  },
  created: async function () {
    if (
      /* this.$route.matched not available at this point of time instead checking on errors below: this.$route.matched.length == 0 || */
      this.$store.state.user.id != 0 &&
      !this.$store.state.error403 &&
      !this.$store.state.error404 &&
      !/^\/event\/[a-zA-Z0-9]{6}$/.test(this.$route.path) /* on join page */
    ) {
      //check on whether the user just requested a trial
      if (trialRequested.get()) {
        this.$emit("redirect");
        this.showRedirectMessage = true;
        let redirectUrl = await this.getSubscriptionRedirectUrl();
        if (redirectUrl != "") {
          trialRequested.set(false);
          window.location.href = redirectUrl;
        } else {
          this.showRedirectMessage = false;
        }
        return;
      }
    }
  },
  computed: {
    error404() {
      return this.$store.state.error404;
    },
    error403() {
      return this.$store.state.error403;
    },
    routingDone() {
      return this.$store.state.routingDone;
    },
    authorized() {
      return this.$store.state.user.id != 0;
    },
    isEventEditor() {
      if (this.$route.matched.length != 1) return false;
      return ["/event/:tag([a-zA-Z0-9]{6})/edit", "/event/new"].includes(this.$route.matched[0].path);
    },
  },
  methods: {
    async getSubscriptionRedirectUrl() {
      let subscriptionRedirectUrl = "";
      let $priceId = "";

      if (this.$store.state.user.premiumAccount.subscriptionId < 0) {
        //need to check directly from state instead of using global hasGrantedSubscription, since this is called before the state is updated
        let subscriptionId = this.$store.state.user.premiumAccount.subscriptionId;
        if (subscriptionId == -1) alert(this.t("/payment/already-subscribed-through-enterprise-contract"));
        if (subscriptionId == -2) alert(this.t("/payment/already-subscribed-by-courtesy"));
        return "";
      } else if (trialRequested.get()) {
        if (
          this.$store.state.user.premiumAccount.trialStarted == 0 &&
          this.$store.state.user.premiumAccount.subscriptionId == 0 &&
          !this.hasPremium
        ) {
          $priceId = "trial";
        } else if (this.$store.state.user.premiumAccount.subscriptionId == 0) {
          if (!this.isTrial) {
            this.showRedirectMessage = false;
            if (confirm(this.t("/payment/already-had-trial-want-subscribe-question")) == true) {
              $priceId = "premium";
              this.showRedirectMessage = true;
            }

            /*let question = this.t("/event/confirm-sending-event-fix", {
                audience: this.t("/event/to-voters"),
              });

              await dialog(
                question,
                [
                  {label: "yes", value: true, key: "Enter"},
                  {label: "do-not-send", value: false, key: "Escape"},
                ],
                {translate: false},
              );*/
          } else {
            this.showRedirectMessage = false;
            if (confirm(this.t("/payment/already-in-trial-want-to-pay-question")) == true) {
              $priceId = "open-customer-portal";
              this.showRedirectMessage = true;
            }
          }
        } else {
          this.showRedirectMessage = false;
          if (confirm(this.t("/payment/already-subscribed-want-review-subscription-question")) == true) {
            $priceId = "open-customer-portal";
            this.showRedirectMessage = true;
          }
        }
      } else {
        if (this.$store.state.user.premiumAccount.subscriptionId == 0) {
          if (!this.isTrial) {
            $priceId = "premium";
          } else {
            this.showRedirectMessage = false;
            if (confirm(this.t("/payment/already-in-trial-want-to-pay-question")) == true) {
              $priceId = "open-customer-portal";
              this.showRedirectMessage = true;
            }
          }
        } else {
          this.showRedirectMessage = false;
          if (confirm(this.t("/payment/already-subscribed-want-review-subscription-question")) == true) {
            $priceId = "open-customer-portal";
            this.showRedirectMessage = true;
          }
        }
      }

      if ($priceId == "") {
        return "";
      } else if ($priceId == "open-customer-portal") {
        subscriptionRedirectUrl = await this.getCustomerPortalUrl();
      } else {
        subscriptionRedirectUrl = await this.getCheckoutUrl($priceId, true);
      }
      return subscriptionRedirectUrl;
    },
    async getCheckoutUrl(internalPriceId, isSubscription) {
      // You will be redirected to Stripe's secure checkout page
      //open url
      if (this.busy) return;
      this.busy = true;
      let response = await apiCall("/payment/getCustomerCheckoutSessionUrl", {
        internalPriceId: internalPriceId,
        mode: isSubscription ? "subscription" : "payment",
        lang: this.$store.state.user.preferences.lang,
      });
      this.busy = false;
      if (response.ok) {
        return response.json; //e.g. "https://checkout.stripe.com/c/pay/ppage_1Ng7BUGfUa33N7MVg1I1y37N#fidkdWxOYHwnPyd1blpxYHZxWjA0SzxLS11CY1BkNjZLMkhTdmBVTn89RExvQkNIXUZRSU9iSFN9bUdiVTN1VUxnU19HdmR2RDBgPX1Tc0pJZDBWYEpGYTcxXXNJPH1nVWw1TmN8YHdzPFJLNTVAcFVMTkhvVicpJ2hsYXYnP34nYnBsYSc%2FJ0tEJyknaHBsYSc%2FJzI2PWQ0MGFjKDFmMmYoMTw1MihkZjNjKGE9NDJjYTw3PDI0MzQzNTc8ZycpJ3ZsYSc%2FJz1gZjQ8N2A2KDQ1NDQoMWY2NShkN2Q0KDYxN2M0MzFmYWFgNjIyMWRgPCd4KSdnYHFkdic%2FXlgpJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ3dgY2B3d2B3SndsYmxrJz8nbXFxdXY%2FKipkdXUoYWBzK2lqanUxK2ZqaCcpJ2lqZmRpYCc%2FJ2RwcWoneCUl"
      } else {
        this.$store.commit("notify", {
          type: "danger",
          message: "unexpected-error",
        });
      }
    },
    async getCustomerPortalUrl() {
      // You will be redirected to Stripe's secure checkout page
      //open url
      if (this.busy) return;
      this.busy = true;
      let response = await apiCall("/payment/getCustomerPortalSessionUrl");
      this.busy = false;
      if (response.ok) {
        return response.json; //e.g. "https://billing.stripe.com/p/session/test_YWNjdF8xTjlOTlhHZlVhMzNON01WLF9PU01jbjltcTRYYjJtcTc1ek41ejJYSkNQdVNhWGtI0100SlSObVwN"
      } else {
        this.$store.commit("notify", {
          type: "danger",
          message: "unexpected-error",
        });
      }
    },
  },
};
</script>
