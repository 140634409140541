var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row gutt-2 gutt-md-2" }, [
    _c("div", { staticClass: "col-12 col-md-6" }, [
      _c("div", { staticClass: "row gutt-2" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "button",
            {
              staticClass: "btn-default p-1",
              on: { click: _vm.authWithGoogle },
            },
            [
              _c("i", { staticClass: "i google" }),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.t("/login/login-with-google")) +
                  "\n        "
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 col-md-6" }, [
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.loginWithEmail.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "row gutt-2" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.email.value,
                    expression: "email.value",
                    modifiers: { trim: true },
                  },
                ],
                class: [
                  "text-field",
                  { "is-wrong": _vm.email.error && _vm.email.showError },
                ],
                attrs: { type: "text", placeholder: _vm.t("/forms/email") },
                domProps: { value: _vm.email.value },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.email, "value", $event.target.value.trim())
                    },
                    function ($event) {
                      _vm.email.showError = false
                    },
                  ],
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form-error" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.email.error) + "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password.value,
                    expression: "password.value",
                  },
                ],
                class: [
                  "text-field",
                  { "is-wrong": _vm.password.error && _vm.password.showError },
                ],
                attrs: {
                  type: "password",
                  placeholder: _vm.t("/forms/password"),
                },
                domProps: { value: _vm.password.value },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.password, "value", $event.target.value)
                    },
                    function ($event) {
                      _vm.password.showError = false
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form-error" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.password.error) + "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row gutt-2" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "button",
                    {
                      class: ["btn-primary", { busy: _vm.busy.loginWithEmail }],
                      attrs: { type: "submit" },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.t("/login/submit")) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "button",
                    {
                      class: [
                        "btn-default w-100",
                        { busy: _vm.busy.forgotPassword },
                      ],
                      attrs: { type: "button" },
                      on: { click: _vm.forgotPassword },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.t("/login/forgot-password")) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showForgotPasswordInstructions,
                    expression: "showForgotPasswordInstructions",
                  },
                ],
                staticClass: "col-12",
              },
              [
                _c("div", { staticClass: "alert-info" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn-dismiss",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.showForgotPasswordInstructions = false
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-times" })]
                  ),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.t("/login/forgot-password-instructions")) +
                      "\n          "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showForgotPasswordSuccess,
                    expression: "showForgotPasswordSuccess",
                  },
                ],
                staticClass: "col-12",
              },
              [
                _c("div", { staticClass: "alert-info" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn-dismiss",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.showForgotPasswordSuccess = false
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-times" })]
                  ),
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.t("/login/forgot-password-success", {
                          email: _vm.email.value,
                        })
                      ) +
                      "\n          "
                  ),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }