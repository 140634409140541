var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-wrap", attrs: { tabindex: "-1" } }, [
    _vm._v("\n  " + _vm._s(_vm.tt("/dialog")) + "\n  "),
    _c("div", { staticClass: "dialog" }, [
      _c("div", { staticClass: "row gutt-2" }, [
        _c("div", { staticClass: "col-12 c" }, [
          _c("strong", [_vm._v(_vm._s(_vm.t("redirecting")) + "...")]),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "row gutt-1 justify-content-between" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }