<template>
  <div class="row gutt-2 gutt-md-2">
    <div class="col-12 col-md-6">
      <div class="row gutt-2">
        <div class="col-12">
          <button class="btn-default p-1" @click="authWithGoogle">
            <i class="i google"></i>
            {{ t("/login/login-with-google") }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <form @submit.prevent="loginWithEmail">
        <div class="row gutt-2">
          <div class="col-12">
            <input
              type="text"
              :class="['text-field', {'is-wrong': email.error && email.showError}]"
              :placeholder="t('/forms/email')"
              v-model.trim="email.value"
              @input="email.showError = false"
            />
            <div class="form-error">
              {{ email.error }}
            </div>
          </div>
          <div class="col-12">
            <input
              type="password"
              :class="['text-field', {'is-wrong': password.error && password.showError}]"
              :placeholder="t('/forms/password')"
              v-model="password.value"
              @input="password.showError = false"
            />
            <div class="form-error">
              {{ password.error }}
            </div>
          </div>
          <div class="col-12">
            <div class="row gutt-2">
              <div class="col">
                <button type="submit" :class="['btn-primary', {busy: busy.loginWithEmail}]">
                  {{ t("/login/submit") }}
                </button>
              </div>
              <div class="col">
                <button
                  type="button"
                  :class="['btn-default w-100', {busy: busy.forgotPassword}]"
                  @click="forgotPassword"
                >
                  {{ t("/login/forgot-password") }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-12" v-show="showForgotPasswordInstructions">
            <div class="alert-info">
              <button type="button" class="btn-dismiss" @click="showForgotPasswordInstructions = false">
                <i class="fa fa-times"></i>
              </button>
              {{ t("/login/forgot-password-instructions") }}
            </div>
          </div>
          <div class="col-12" v-show="showForgotPasswordSuccess">
            <div class="alert-info">
              <button type="button" class="btn-dismiss" @click="showForgotPasswordSuccess = false">
                <i class="fa fa-times"></i>
              </button>
              {{ t("/login/forgot-password-success", {email: email.value}) }}
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {hideModal} from "../../ui";
import {apiCall} from "../../api";
import isEmail from "validator/lib/isEmail";
import {isJust, just, trialRequested} from "../../helpers";

export default {
  data() {
    return {
      busy: {
        loginWithEmail: false,
        forgotPassword: false,
      },
      showForgotPasswordInstructions: false,
      showForgotPasswordSuccess: false,
      email: {
        value: "",
        error: "",
        showError: false,
        validators: {
          "/forms/email-empty": (_) => this.email.value == "",
          "/forms/email-malformed": (_) => !isEmail(this.email.value),
        },
      },
      password: {
        value: "",
        error: "",
        showError: false,
        validators: {
          "/forms/password-empty": (_) => this.password.value == "",
        },
      },
    };
  },
  methods: {
    async redirectUrl() {
      let countobj = await apiCall("/event/countOfAll"),
        redirect = "/events";
      if (countobj.json.active + countobj.json.drafts + countobj.json.archived == 0 && countobj.json.joined > 0) {
        redirect = "/events/joined";
      }
      return redirect;
    },

    async authWithGoogle() {
      let successRedirectUrl =
        window.location.pathname == "/" ? window.location.origin + (await this.redirectUrl()) : window.location.href;
      let response = await apiCall("/services/google/auth", {
        successUrl: successRedirectUrl,
        failureUrl: window.location.href,
        preferences: this.$store.state.user.preferences,
        requestAccessToCalendar: false,
      });
      if (response.ok) {
        l4a.event("google-auth-start");
        this.$emit("redirect");
        window.location.href = response.json;
      } else {
        this.$store.commit("notify", {
          type: "danger",
          message: "unexpected-error",
        });
      }
    },

    async loginWithEmail() {
      if (this.busy.loginWithEmail) return;

      let anyError = false;
      ["email", "password"].forEach((name) => {
        let input = this[name];
        for (let errCode in input.validators) {
          let isInvalid = input.validators[errCode];
          if (isInvalid()) {
            input.error = this.t(errCode);
            input.showError = true;
            anyError = true;
            break;
          }
        }
      });

      if (anyError) return;

      this.busy.loginWithEmail = true;
      let response = await apiCall("/user/login", {
        email: this.email.value,
        password: this.password.value,
      });
      this.busy.loginWithEmail = false;

      if (response.ok) {
        if (isJust(response.json)) {
          l4a.event("email-login-success");
          hideModal();
          this.$store.update("user", just(response.json));
          if (trialRequested.get()) {
            window.location.reload(); //need to reload since need to initiate /Root.vue that will take care of further steps
          } else {
            if (this.$route.path == "/") this.redirectUrl().then((redirect) => this.$router.push(redirect));
          }
        } else {
          this.email.error = this.t("/forms/email-wrong");
          this.email.showError = true;
          this.password.error = this.t("/forms/or-password-wrong");
          this.password.showError = true;
        }
      } else {
        if (response.json.message) {
          this.$store.commit("notify", {
            type: "danger",
            message: response.json.message,
          });
        } else {
          this.$store.commit("notify", {
            type: "danger",
            message: "unexpected-error",
          });
        }
      }
    },

    async forgotPassword() {
      if (this.busy.forgotPassword) return;

      let anyError = false;
      for (let errCode in this.email.validators) {
        let isInvalid = this.email.validators[errCode];
        if (isInvalid()) {
          this.email.error = this.t(errCode);
          this.email.showError = true;
          anyError = true;
          break;
        }
      }

      if (anyError) {
        this.showForgotPasswordInstructions = true;
        this.showForgotPasswordSuccess = false;
        return;
      }

      this.busy.forgotPassword = true;
      let response = await apiCall("/user/forgot-password", this.email.value);
      this.busy.forgotPassword = false;

      if (response.ok && response.json === true) {
        this.showForgotPasswordInstructions = false;
        this.showForgotPasswordSuccess = true;
      } else {
        this.$store.commit("notify", {
          type: "danger",
          message: "unexpected-error",
        });
      }
    },

    cancel() {
      this.showForgotPasswordInstructions = false;
      this.showForgotPasswordSuccess = false;
      if (this.loginError) this.$store.commit("dismissNotification", this.loginError.id);
    },
  },
};
</script>
