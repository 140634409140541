var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "header-clock-wrap",
        { active: _vm.active, collapsed: _vm.collapsed },
      ],
    },
    [
      _c("div", { staticClass: "header-clock", on: { click: _vm.click } }, [
        _vm._v("\n    " + _vm._s(_vm.time) + " ("),
        _vm.tzAbbrTitle
          ? _c("span", { attrs: { title: _vm.tzAbbrTitle } }, [
              _vm._v(_vm._s(_vm.tzAbbr)),
            ])
          : _vm.tzAbbr
          ? _c("span", [_vm._v(_vm._s(_vm.tzAbbr))])
          : _vm._e(),
        _vm._v(_vm._s(_vm.tzAbbr ? " " : "") + _vm._s(_vm.tzOffset) + ")\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }