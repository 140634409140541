var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { class: { "main-page": _vm.$route.path == "/" }, attrs: { id: "footer" } },
    [
      _c("nav", { staticClass: "navbar container" }, [
        _c("ul", { staticClass: "links" }, [
          _vm._v("\n      " + _vm._s(_vm.tt("/menu")) + "\n      "),
          _c(
            "li",
            [
              _c(
                "RouterLink",
                { attrs: { to: "/about-us", target: "_blank" } },
                [_vm._v(_vm._s(_vm.t("about-us")))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("RouterLink", { attrs: { to: "/cases", target: "_blank" } }, [
                _vm._v(_vm._s(_vm.t("cases"))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("RouterLink", { attrs: { to: "/terms", target: "_blank" } }, [
                _vm._v(_vm._s(_vm.t("terms"))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "RouterLink",
                { attrs: { to: "/privacy", target: "_blank" } },
                [_vm._v(_vm._s(_vm.t("privacy")))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "RouterLink",
                {
                  staticClass: "before-social-buttons",
                  attrs: { to: "/support", target: "_blank" },
                },
                [_vm._v(_vm._s(_vm.t("support")))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("li", [
            _c(
              "div",
              { staticClass: "social-buttons" },
              [
                _c(
                  "RouterLink",
                  {
                    staticClass: "blog",
                    attrs: { to: "/blog", target: "_blank" },
                  },
                  [_vm._v(_vm._s(_vm.t("blog")))]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "linkedin",
                    attrs: {
                      href: "https://www.linkedin.com/company/loop4-app",
                      target: "_blank",
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 24 24",
                          fill: "white",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M22.23 0H1.77C.79 0 0 .77 0 1.72v20.56C0 23.23.79 24 1.77 24h20.46c.98 0 1.77-.77 1.77-1.72V1.72C24 .77 23.21 0 22.23 0zM7.19 20.45H3.56V9h3.63v11.45zM5.38 7.63c-1.16 0-1.88-.77-1.88-1.74C3.5 4.77 4.22 4 5.43 4s1.88.77 1.88 1.74c0 .97-.72 1.89-1.93 1.89zm14.36 12.82h-3.63v-5.77c0-1.45-.52-2.45-1.83-2.45-1 0-1.56.67-1.82 1.32-.1.24-.12.58-.12.92v5.97h-3.63s.05-9.7 0-10.71h3.63v1.52c.48-.74 1.33-1.8 3.24-1.8 2.37 0 4.14 1.55 4.14 4.88v6.11z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "instagram",
                    attrs: {
                      href: "https://www.instagram.com/loop4_scheduler",
                      target: "_blank",
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 24 24",
                          fill: "white",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M12 2.16c3.18 0 3.56.01 4.82.07 1.17.05 1.81.24 2.23.4.56.21.96.46 1.38.88s.67.82.88 1.38c.16.42.34 1.06.4 2.23.06 1.26.07 1.64.07 4.82s-.01 3.56-.07 4.82c-.05 1.17-.24 1.81-.4 2.23-.21.56-.46.96-.88 1.38s-.82.67-1.38.88c-.42.16-1.06.34-2.23.4-1.26.06-1.64.07-4.82.07s-3.56-.01-4.82-.07c-1.17-.05-1.81-.24-2.23-.4a3.91 3.91 0 0 1-1.38-.88 3.91 3.91 0 0 1-.88-1.38c-.16-.42-.34-1.06-.4-2.23C2.17 15.75 2.16 15.37 2.16 12s.01-3.56.07-4.82c.05-1.17.24-1.81.4-2.23.21-.56.46-.96.88-1.38s.82-.67 1.38-.88c.42-.16 1.06-.34 2.23-.4C8.44 2.17 8.82 2.16 12 2.16M12 0C8.741 0 8.332.012 7.052.07 5.775.128 4.74.304 3.8.696 2.843 1.097 2.1 1.841 1.699 2.8c-.392.94-.568 1.975-.626 3.252C.993 8.332.98 8.741.98 12c0 3.259.013 3.668.07 4.948.058 1.277.234 2.312.626 3.252.401.959 1.144 1.703 2.101 2.104.94.392 1.975.568 3.252.626 1.28.058 1.689.07 4.948.07s3.668-.013 4.948-.07c1.277-.058 2.312-.234 3.252-.626.959-.401 1.703-1.144 2.104-2.101.392-.94.568-1.975.626-3.252.058-1.28.07-1.689.07-4.948s-.013-3.668-.07-4.948c-.058-1.277-.234-2.312-.626-3.252-.401-.959-1.144-1.703-2.101-2.104-.94-.392-1.975-.568-3.252-.626C15.668.993 15.259.98 12 .98z",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M12 5.84A6.16 6.16 0 1 0 18.16 12 6.16 6.16 0 0 0 12 5.84zm0 10.16a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm6.4-10.75a1.44 1.44 0 1 0 1.44 1.44 1.44 1.44 0 0 0-1.44-1.44z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }