var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-wrap hidden",
      attrs: { id: "signup-modal", tabindex: "-1" },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.cancel.apply(null, arguments)
        },
      },
    },
    [
      _vm._v("\n  " + _vm._s(_vm.tt("/signup")) + "\n  "),
      _c("div", { staticClass: "modal-header" }, [
        _c(
          "button",
          { staticClass: "modal-back-btn", on: { click: _vm.cancel } },
          [_c("i", { staticClass: "i back-lg" })]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-title" }, [
          _vm._v("\n      " + _vm._s(_vm.t("title")) + "\n      "),
          _c("span", {
            staticStyle: { "font-size": "medium" },
            domProps: { innerHTML: _vm._s(_vm.t("title-addition")) },
            on: {
              click: function ($event) {
                _vm.hideModal()
                _vm.showModal("#login-modal")
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "modal-btn", on: { click: _vm.cancel } }, [
          _c("i", { staticClass: "i times-lg" }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-body" },
        [_c("SignUpForm", { ref: "signUpForm" })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }