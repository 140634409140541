var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { attrs: { id: "header" } }, [
    _c(
      "nav",
      { staticClass: "navbar container" },
      [
        _vm.hasPremium
          ? _c("RouterLink", {
              staticClass: "i logo-premium",
              attrs: { to: "/" },
            })
          : _c("RouterLink", { staticClass: "i logo", attrs: { to: "/" } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "top-menu" },
          [
            _c("HeaderClock"),
            _vm._v(" "),
            !_vm.authorized
              ? _c("ul", { staticClass: "links" }, [
                  _vm._v("\n        " + _vm._s(_vm.tt("/menu")) + "\n        "),
                  _c(
                    "li",
                    [
                      _c(
                        "RouterLink",
                        { attrs: { to: "/search", exact: "" } },
                        [
                          _c("i", { staticClass: "fa fa-search hidden-md" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "hidden-sm-down" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.t("search")) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.$store.state.error403
                    ? _c("li", [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.showModal("#login-modal")
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-sign-in-alt hidden-md",
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "hidden-sm-down" }, [
                              _vm._v(_vm._s(_vm.t("log-in"))),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showModal("#signup-modal")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-user-plus hidden-md" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "hidden-sm-down" }, [
                          _vm._v(_vm._s(_vm.t("sign-up"))),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [_c("div", [_c("LanguageSwitch")], 1)]),
                ])
              : _c("ul", { staticClass: "links" }, [
                  _vm._v("\n        " + _vm._s(_vm.tt("/menu")) + "\n        "),
                  _c(
                    "li",
                    [
                      _c(
                        "RouterLink",
                        { attrs: { to: "/search", exact: "" } },
                        [
                          _c("i", { staticClass: "fa fa-search hidden-md" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "hidden-sm-down" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.t("search")) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("RouterLink", { attrs: { to: "/event/new" } }, [
                        _c("i", {
                          staticClass: "fa fa-calendar-plus hidden-md",
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "hidden-sm-down" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.t("add-event")) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("RouterLink", { attrs: { to: "/events" } }, [
                        _c("i", {
                          staticClass: "fa fa-calendar-alt hidden-md",
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "hidden-sm-down" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.t("my-events")) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", [_c("div", [_c("LanguageSwitch")], 1)]),
                  _vm._v(" "),
                  _c("li", [
                    _c("div", { on: { click: _vm.showDropdown } }, [
                      _c("i", { staticClass: "fa fa-user-circle" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "hidden-md-down" }, [
                        _vm._v(_vm._s(_vm.accountName)),
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fa fa-angle-down hidden-md-down",
                      }),
                    ]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "dropdown-menu hidden" }, [
                      _c("li", { staticClass: "hidden-md-down" }, [
                        _c("div", { staticClass: "read-only" }, [
                          _c("span", [_vm._v(_vm._s(_vm.accountEmail))]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "separator hidden-md-down" }),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("RouterLink", { attrs: { to: "/my-account" } }, [
                            _c("i", { staticClass: "fa fa-cogs" }),
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.t("my-account")) +
                                "\n              "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("li", { staticClass: "separator" }),
                      _vm._v(" "),
                      _c("li", [
                        _c("div", { on: { click: _vm.logout } }, [
                          _c("i", { staticClass: "fa fa-sign-out-alt" }),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.t("log-out")) +
                              "\n              "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }