<template>
  <div v-if="visible" class="cookie-disclaimer">
    <div class="flex-grow-1"></div>
    <div v-html="t('/dialog/cookie-disclaimer')" />
    <button class="btn-default ml-1" @click="ok">{{ t("/dialog/ok") }}</button>
    <div class="flex-grow-1"></div>
  </div>
</template>

<script>
import {l4aBaseUrl} from "../../config.js";
const LS_KEY = "hasSeenCookieDisclaimer";

export default {
  data() {
    return {
      visible: localStorage.getItem(LS_KEY) === null,
    };
  },
  methods: {
    ok() {
      this.visible = false;
      localStorage.setItem(LS_KEY, "1");
      l4a.event("tracking-start", {
        referrer: document.referrer,
        page: location.pathname,
        ipAddr: "{IP}",
        userAgent: navigator.userAgent,
        viewport: {
          width: window.innerWidth,
          height: window.innerHeight,
          dpr: window.devicePixelRatio,
        },
      });
    },
  },
};
</script>
