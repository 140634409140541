import padStart from "lodash/padStart";
import {supportedLanguages} from "../config";
import moment from "moment";
import {apiCall} from "./api";

export function isJust(maybe) {
  return maybe[0] == 0;
}

export function isNothing(maybe) {
  return maybe[0] == 1;
}

export function just(maybe) {
  if (isNothing(maybe)) throw new Error('"Maybe" value is "Nothing" so there is no value to get from it.');
  return maybe[1];
}

export function isExactTime(time) {
  return time[0] == 0;
}

export function exactTime(time) {
  if (!isExactTime(time)) throw new Error('Time is not an "exactTime" value');
  return time[1];
}

export function isInterval(time) {
  return time[0] == 1;
}

export function interval(time) {
  if (!isInterval(time)) throw new Error('Time is not an "interval" value');
  return time[1];
}

export function isOptions(time) {
  return time[0] == 2;
}

export function options(time) {
  if (!isOptions(time)) throw new Error('Time is not an "options" value');
  return time[1];
}

export function isWholeDay(duration) {
  return duration[0] == 0;
}

export function isSeconds(duration) {
  return duration[0] == 1;
}

export function seconds(duration) {
  if (!isSeconds(duration)) throw new Error('Duration is not an "seconds" value');
  return duration[1];
}

export function isUnlimited(participation) {
  return participation[0] == 0;
}

export function isLimit(participation) {
  return participation[0] == 1;
}

export function limit(participation) {
  if (!isLimit(participation)) throw new Error('Participation is not an "limit" value');
  return participation[1];
}

export function isEmails(participation) {
  return participation[0] == 2;
}

export function emails(participation) {
  if (!isEmails(participation)) throw new Error('Participation is not an "emails" value');
  return participation[1];
}

export function HHMMtoSeconds(value) {
  if (!value) return 0;

  // remove non numeric characters from string ends
  value = value.replace(/^\W+|\W+$/g, "");
  // isNaN check prevents NaN to be returned in case all characters were removed
  value = value
    .split(":")
    .map((x) => parseInt(x.replace(/\D/g, "")))
    .map((x) => (isNaN(x) ? 0 : Math.abs(x)));
  let result = 0;

  if (value.length == 1) {
    // hours as seconds
    result = value[0] * 3600;
  } else if (value.length == 2) {
    // hours and minutes as seconds
    result = value[0] * 3600 + value[1] * 60;
  }
  return result;
}

export function secondsToHHMM(value) {
  return value ? Math.floor(value / 3600) + ":" + padStart(String(Math.floor((value % 3600) / 60)), 2, "0") : "00:00";
}

/**
 * Get default preferences
 * @return {Object}
 */
export function getDefaultPreferences() {
  let lang = navigator.language.substring(0, 2);
  if (supportedLanguages.indexOf(lang) == -1) lang = supportedLanguages[0]; // fallback
  let defaults = {
    lang: lang,
    timezone: getBrowserTz(),
    timezoneApproved: false,
    timeDisplay: Number(new Date(1999, 1, 1, 21).toString().includes("21:00:00")), // 0 - am/pm, 1 - 24h,
    dateFormat: "ddd, D MMM YYYY", // use clear and rather commonly used default with day of week mentioning, it will be used in multiple places
    weekStartsAt: 0, // always Monday for now
    defaultDesign: "design-purple-gifts",
  };
  let storedPreferences = localStorage.getItem("preferences");
  if (storedPreferences) {
    storedPreferences = JSON.parse(storedPreferences);
    for (let key of ["lang", "timezone"]) {
      if (key in storedPreferences) defaults[key] = storedPreferences[key];
    }
  }
  return defaults;
}

/**
 * Get empty user object
 * @return {Object}
 */
export function getEmptyUser() {
  return {
    id: 0,
    hash: "",
    email: "",
    phone: "",
    name: "",
    type: 0,
    latestActivity: 0,
    preferences: getDefaultPreferences(),
    integratedWith: {
      googleProfile: false,
      googleCalendar: false,
    },
    accessTokens: {
      google: "hidden",
      braintree: "hidden",
    },
    premiumAccount: {
      showTrialDialog: false,
      trialStarted: 0,
      expires: 0,
      smsCounter: 0,
      subscriptionId: 0,
      cancelled: 0,
      receivedFreeSms: 0,
    },
    created: 0,
  };
}

/**
 * Get empty event object
 * @return {Object}
 */
export async function getEmptyEvent() {
  let response = await apiCall("/event/generate-tag");
  return {
    id: 0,
    tag: response.json,
    organizerId: 0,
    published: false,
    archived: false,
    duration: [1, 3600], // Not whole day, 1 hour
    time: [0, timeToFutureFormatted(30 * 60, true, 30)], // Exact time, ½ hour later
    lastTimeOptions: [],
    recurrence: 0,
    place: "",
    timezone: "",
    title: "",
    cardDesign: getDefaultPreferences().defaultDesign,
    instructions: "",
    showInstrToAll: true,
    participation: [0, null], // Unlimited
    lock: [0, null],
    notifications: {
      onVote: true,
      onJoin: true,
      onLeave: true,
      onLimitReached: true,
      onAllJoined: true,
    },
    question: "",
    organizerName: "",
    plusOne: 0,
    aiInvitations: [],
  };
}

/**
 * Get empty participant object
 * @return {Object}
 */
export function getEmptyEventParticipant() {
  return {
    eventId: 0,
    userId: 0,
    reminder: [1, 3600],
    notificationTransport: {
      email: true,
      sms: false,
    },
    syncWith: {
      google: false,
    },
    joinedAt: 0,
    answer: "",
    plusOne: 0,
  };
}

/**
 * Format duration time for event card
 */
export function fmtDurationTime(duration) {
  let durationFormatted = "";

  if (Array.isArray(duration)) {
    durationFormatted = isWholeDay(duration)
      ? this.t("/event-card/duration-whole-day")
      : isSeconds(duration)
      ? this.t("/event-card/duration-time", {
          duration: secondsToHHMM(seconds(duration)),
        })
      : "";
  }

  return durationFormatted;
}

/**
 * Format event time for event card
 */
export function fmtEventTime(event, inEditor = true) {
  let timezone;
  if (event.organizerId == this.$store.state.user.id) {
    timezone = this.hasPremium ? event.timezone : "";
  } else {
    timezone = this.isPremiumEvent ? event.timezone : "";
  }
  if (isExactTime(event.time)) {
    if (inEditor) {
      return fmtTime.call(this, exactTime(event.time), timezone);
    } else {
      return fmtTime.call(this, exactTime(event.time), timezone, true, true, true);
    }
  } else if (isInterval(event.time)) {
    let [start, end] = interval(event.time);
    if (inEditor) {
      return this.t("/event-card/between-start-and-end", {
        start: fmtTime.call(this, start, timezone),
        end: fmtTime.call(this, end, timezone),
      });
    } else {
      return this.t("/event-card/between-start-and-end", {
        start: fmtTime.call(this, start, timezone, true, true, true),
        end: fmtTime.call(this, end, timezone, true, true, true),
      });
    }
  } else if (isOptions(event.time)) {
    let opts = options(event.time);
    if (inEditor) {
      if (opts.length == 2) {
        return this.t("/event-card/first-or-second", {
          first: fmtTime.call(this, opts[0], timezone),
          second: fmtTime.call(this, opts[1], timezone),
        });
      } else {
        return this.t("/event-card/first-or-other", {
          first: fmtTime.call(this, opts[0], timezone),
          n: opts.length - 1,
        });
      }
    } else {
      if (opts.length == 2) {
        return this.t("/event-card/first-or-second", {
          first: fmtTime.call(this, opts[0], timezone, true, true, true),
          second: fmtTime.call(this, opts[1], timezone, true, true, true),
        });
      } else {
        return this.t("/event-card/first-or-other", {
          first: fmtTime.call(this, opts[0], timezone, true, true, true),
          n: opts.length - 1,
        });
      }
    }
  }
}

/**
 * Format time
 * @param {number} time UNIX timestamp
 * @param {string} timezone optional, additional timezone
 * @param {boolean} returnTime optional, deault true, can be used to not display time if no timezone is set
 * @param {boolean} returnTimezoneInAnyCase optional, deault false, return timezone in all cases
 * @param {string} separator optional, default ", ", can be used to change separator between date / time and timezone
 * @return {string} formatted time
 */
export function fmtTime(
  time,
  timezone = "",
  returnTime = true,
  returnTimezoneInAnyCase = false,
  returnFormattedHtml = false,
) {
  let preferences = this.$store.state.user.preferences,
    timeFormat = preferences.timeDisplay == 1 ? "HH:mm" : "h:mm a",
    $useEventTimeZone = false,
    timeBreaking = ", ",
    timezoneBreaking = returnFormattedHtml ? "&nbsp;" : " ";
  if (timezone != "") {
    $useEventTimeZone = true;
  }
  if ((!$useEventTimeZone || timezone == preferences.timezone) && !returnTimezoneInAnyCase) {
    if (returnTime) {
      return moment.unix(time).format(preferences.dateFormat + timeBreaking + timeFormat);
    } else {
      return moment.unix(time).format(preferences.dateFormat);
    }
  } else {
    if (!$useEventTimeZone) {
      timezone = preferences.timezone;
    }

    let m = moment.unix(time),
      userTzDate = m.format(preferences.dateFormat),
      userTzTime = m.format(timeFormat),
      userTzAbbr = m.format("zz"),
      userTzOffs = m.format("Z");

    m = m.tz(timezone);
    let eventTzDate = m.format(preferences.dateFormat),
      eventTzTime = m.format(timeFormat),
      eventTzAbbr = m.format("zz"),
      eventTzOffs = m.format("Z");

    if (userTzDate == eventTzDate && userTzTime == eventTzTime && !returnTimezoneInAnyCase) {
      if (returnTime) {
        return userTzDate + timeBreaking + userTzTime;
      } else {
        return userTzDate;
      }
    }

    let userTzDateTime = returnTime ? userTzDate + timeBreaking + userTzTime : userTzDate,
      userTzAbbrOffs =
        timezoneBreaking +
        " (" +
        (userTzAbbr.charAt(0) == "+" || userTzAbbr.charAt(0) == "-" ? userTzOffs : userTzAbbr + " " + userTzOffs) +
        ")";

    if (returnFormattedHtml) userTzAbbrOffs = "<span class='smaller'>" + userTzAbbrOffs + "</span>";

    if (!$useEventTimeZone) {
      return `${userTzDateTime}${userTzAbbrOffs}`;
    } else {
      let eventTzDateTime = returnTime ? eventTzDate + timeBreaking + eventTzTime : eventTzDate,
        eventTzAbbrOffs =
          timezoneBreaking +
          " (" +
          (eventTzAbbr.charAt(0) == "+" || eventTzOffs.charAt(0) == "-"
            ? eventTzOffs
            : eventTzAbbr + " " + eventTzOffs) +
          ")",
        userTzDateTime = returnTime
          ? userTzDate != eventTzDate
            ? userTzDate + timeBreaking + userTzTime
            : userTzTime
          : userTzDate != eventTzDate
          ? userTzDate
          : "";

      if (returnFormattedHtml) {
        eventTzAbbrOffs = "<span class='smaller'>" + eventTzAbbrOffs + "</span>";
        userTzDateTime = "<span class='smaller'>" + userTzDateTime + "</span>";
      }

      let separator = returnFormattedHtml ? "<br/>" : "; ";
      if (userTzDateTime != "") {
        return `${eventTzDateTime}${eventTzAbbrOffs}${separator}${userTzDateTime}${userTzAbbrOffs}`;
      } else {
        return `${eventTzDateTime}${eventTzAbbrOffs}`;
      }
    }
  }
}

/**
 * get event in the future for new duplicated event
 * @param {*} time
 */
export function newEventTime(time) {
  if (isExactTime(time)) {
    if (exactTime(time) < Math.round(Date.now() / 1000)) {
      // round the starting time to next half hour
      time[1] = timeToFutureFormatted(30 * 60, true, 30);
    }
    return time;
  }

  if (isOptions(time)) {
    let optionTime = options(time);
    if (optionTime[0] < timeToFutureFormatted(0, false)) {
      time[1][0] = timeToFutureFormatted(30 * 60, true, 30);
      time[1][1] = timeToFutureFormatted(30 * 60 + 7200, true, 30);
    }

    return time;
  }

  return time;
}

/**
 * return current epoch time in seconds added with given amount of seconds
 * and rounded to closest 5 minutes by default
 * @returns {number}
 * @param {number} seconds how far into the future a time is requested
 * @param {boolean} round should the resulting time be rounded to "minutes"
 * @param {number} minutes time is rounded to the closest minutes
 */
export function timeToFutureFormatted(seconds, round = true, minutes = 5) {
  // "minutes" intervals
  let roundTo = 1000 * 60 * minutes;
  let nowMs = Date.now();

  if (round) {
    return Math.round(nowMs / roundTo) * (roundTo / 1000) + +seconds;
  }

  return Math.round(Date.now() / 1000) + +seconds;
}

/**
 * Is strong password
 * @param {string} password
 * @return {boolean}
 */
export function isStrongPassword(password) {
  return password.length >= 8 && (/[A-Z]/.test(password) || /[0-9]/.test(password) || /[^A-Za-z0-9]/.test(password));
}

/**
 * Mappings from timezone offsets to the timezone IANA identifier
 * Keys are done according to formula:
 *      stdOff + "" + dstOff
 * Where stdOff is the standard offset in minutes and dstOff is the offset of the summer time a.k.a daylight saving time.
 * For timezones that do not observe DST both offsets are equal.
 * Values are selected to represent the most populated regions.
 */
const offsetToTz = {
  840840: "Pacific/Kiritimati",
  780780: "Pacific/Kanton",
  765825: "Pacific/Chatham",
  720780: "Pacific/Auckland",
  720720: "Asia/Kamchatka",
  660720: "Pacific/Norfolk",
  660660: "Asia/Sakhalin",
  630660: "Australia/Lord_Howe",
  600660: "Australia/Sydney",
  600600: "Australia/Brisbane",
  570630: "Australia/Adelaide",
  570570: "Australia/Darwin",
  540540: "Asia/Tokyo",
  525525: "Australia/Eucla",
  480480: "Asia/Singapore",
  420420: "Asia/Bangkok",
  390390: "Asia/Yangon",
  360360: "Asia/Almaty",
  345345: "Asia/Kathmandu",
  330330: "Asia/Kolkata",
  300300: "Asia/Karachi",
  270270: "Asia/Kabul",
  240240: "Asia/Dubai",
  210210: "Asia/Tehran",
  180180: "Europe/Moscow",
  120180: "Europe/Riga",
  120120: "Africa/Johannesburg",
  60120: "Europe/Berlin",
  6060: "Africa/Lagos",
  600: "Europe/Dublin",
  "0120": "Antarctica/Troll",
  "060": "Europe/London",
  "00": "Africa/Abidjan",
  "-600": "Atlantic/Azores",
  "-60-60": "Atlantic/Cape_Verde",
  "-120-120": "America/Noronha",
  "-180-120": "America/Nuuk",
  "-180-180": "America/Sao_Paulo",
  "-210-150": "America/St_Johns",
  "-240-180": "America/Santiago",
  "-240-240": "America/Puerto_Rico",
  "-300-240": "America/New_York",
  "-300-300": "America/Cancun",
  "-360-300": "America/Chicago",
  "-360-360": "America/Costa_Rica",
  "-420-360": "America/Denver",
  "-420-420": "America/Phoenix",
  "-480-420": "America/Los_Angeles",
  "-480-480": "Pacific/Pitcairn",
  "-540-480": "America/Nome",
  "-540-540": "Pacific/Gambier",
  "-570-570": "Pacific/Marquesas",
  "-600-540": "America/Adak",
  "-600-600": "Pacific/Tahiti",
  "-660-660": "Pacific/Niue",
};

/**
 * Detect user's timezone from browser's data
 * @returns {string} IANA tz identifier, like "Europe/Helsinki"
 */
function getBrowserTz() {
  let result = "";
  try {
    result = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    // do nothing
  }
  // If timezone name is something like "Country/City" then we are done
  if (result.indexOf("/") != -1) return result;

  // Otherwise we need to figure out it
  // If we will know the time offset and whether the DST is in use,
  // we are close to know the timezone

  let firstJanuaryInMyTz = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0),
    firstJanuaryInGMT = new Date(firstJanuaryInMyTz.toGMTString().replace("GMT", "")),
    stdOff = (firstJanuaryInMyTz - firstJanuaryInGMT) / (1000 * 60); // standard time offset

  let firstJuneInMyTz = new Date(new Date().getFullYear(), 6, 1, 0, 0, 0, 0),
    firstJuneInGMT = new Date(firstJuneInMyTz.toGMTString().replace("GMT", "")),
    dstOff = (firstJuneInMyTz - firstJuneInGMT) / (1000 * 60); // summer time offset

  let key = stdOff + "" + dstOff;
  return key in offsetToTz ? offsetToTz[key] : "Europe/London"; // fallback
}

export const TRIAL_PERIOD_LENGTH = 30 * 24 * 60 * 60;

/**
 * Persistent flag for storing the fact that user have requested the trial
 */
export const trialRequested = {
  get() {
    return (
      1 ==
      parseInt(
        document.cookie
          .split("; ")
          .find((row) => row.startsWith("trialRequested="))
          ?.split("=")[1],
      )
    );
  },
  set(value) {
    if (value == true) {
      let expiry = new Date();
      expiry.setTime(expiry.getTime() + 1000 * 60 * 60 * 1); // 1 hour
      document.cookie = `trialRequested=1;expires=${expiry.toUTCString()};samesite=strict;secure;path=/`;
    } else {
      document.cookie = "trialRequested=; expires=Thu, 01 Jan 1970 00:00:00 GMT; samesite=strict; secure";
    }
  },
};
