var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    {
      staticClass: "notifications-tray",
      attrs: { duration: _vm.duration, tag: "div" },
      on: { enter: _vm.enter, leave: _vm.leave },
    },
    [
      _vm._v("\n  " + _vm._s(_vm.tt("/notifications")) + "\n  "),
      _vm._l(_vm.notifications, function (notification, id) {
        return _c(
          "div",
          { key: "Notification" + id, class: "alert-" + notification.type },
          [
            _c(
              "button",
              {
                staticClass: "btn-dismiss",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.dismiss(id)
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-times" })]
            ),
            _vm._v(" "),
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.t(notification.message, notification.params)
                ),
              },
            }),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }