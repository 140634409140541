<template>
  <select v-model="currentLang" class="language-switch">
    <option v-for="lang in supportedLanguages" :key="lang" :value="lang">
      {{ lang.toUpperCase() }}
    </option>
  </select>
</template>

<script>
import {supportedLanguages} from "../../config";
import {apiCall} from "../api";

export default {
  data() {
    return {
      supportedLanguages,
    };
  },
  computed: {
    currentLang: {
      get() {
        return this.$store.state.user.preferences.lang;
      },
      async set(value) {
        this.$store.update("user.preferences.lang", value);
        if (this.$store.state.user.id != 0) {
          let response = await apiCall("/user/set-preferences", this.$store.state.user.preferences);
          if (!response.ok || response.json !== true) {
            this.$store.commit("notify", {
              type: "danger",
              message: "unexpected-error",
            });
          }
        }
      },
    },
  },
};
</script>
