import {apiUrl} from "../config";

var sessionExpires = Infinity;
setInterval(function () {
  if (sessionExpires <= Date.now()) {
    window.onbeforeunload = null;
    window.location.reload();
  }
}, 1000);

export async function apiCall(endpoint, body = null) {
  var url = apiUrl + endpoint,
    result = {
      ok: false,
      httpStatus: 0,
      httpReason: "",
      error: "",
      json: null,
    },
    errorMessage = "",
    response;

  try {
    response = await fetch(url, {
      headers: {Accept: "application/json"},
      credentials: "include",
      method: "POST",
      body: JSON.stringify(body),
    });
  } catch (e) {
    errorMessage = e.message;
  }

  if (response == undefined) {
    result.error = "disconnected";
  } else {
    if (response.headers.has("Session-Expires")) {
      sessionExpires = parseInt(response.headers.get("Session-Expires")) * 1000;
    }
    result.ok = response.ok && errorMessage == "";
    result.httpStatus = response.status;
    result.httpReason = response.statusText;
    result.error = errorMessage;
    var text = await response.text();
    result.json = JSON.parse(text);
    if (response.status == 500) console.error("Api error", result.json);
  }

  return result;
}
