<template>
  <transition-group :duration="duration" @enter="enter" @leave="leave" tag="div" class="notifications-tray">
    {{ tt("/notifications") }}
    <div v-for="(notification, id) in notifications" :key="'Notification' + id" :class="'alert-' + notification.type">
      <button type="button" class="btn-dismiss" @click="dismiss(id)">
        <i class="fa fa-times"></i>
      </button>
      <div v-html="t(notification.message, notification.params)" />
    </div>
  </transition-group>
</template>

<script>
import {expandHeight, collapseHeight} from "../ui";

export default {
  data() {
    return {
      duration: 300,
    };
  },
  computed: {
    notifications() {
      let allowedTypes = ["info", "success", "danger"],
        filtered = {};
      for (let id in this.$store.state.notifications) {
        let n = this.$store.state.notifications[id];
        if (allowedTypes.indexOf(n.type) != -1) filtered[id] = n;
      }
      return filtered;
    },
  },
  methods: {
    dismiss(id) {
      this.$store.commit("dismissNotification", id);
    },
    enter(el) {
      expandHeight(el, this.duration);
    },
    leave(el) {
      collapseHeight(el, this.duration);
    },
  },
  updated() {
    let i = 0;
    for (let id in this.notifications) {
      let notification = this.notifications[id];
      if (typeof notification.dismissOn == "object") {
        let element = this.$el.children[i].lastElementChild.querySelector(notification.dismissOn.element);
        if (element && !element["on" + notification.dismissOn.event])
          element["on" + notification.dismissOn.event] = this.dismiss.bind(this, id);
      }
    }
  },
};
</script>
