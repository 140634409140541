function redraw(element) {
  element.offsetWidth;
  element.offsetHeight;
}

export function showModal(modal) {
  if (typeof modal == "string") modal = document.querySelector(modal);
  if (!modal) return;

  var alreadyOpenedModal = document.querySelector(".modal-wrap.visible");

  if (alreadyOpenedModal) {
    if (modal === alreadyOpenedModal) return;
    alreadyOpenedModal.classList.remove("visible");
    setTimeout((_) => {
      alreadyOpenedModal.classList.add("hidden");
      modal.classList.remove("hidden");
      document.body.style.overflow = "auto";
      redraw(modal);
      modal.classList.add("visible");
    }, 300);
  } else {
    modal.classList.remove("hidden");
    redraw(modal);
    modal.classList.add("visible");

    if (modal.id === "signup-modal") {
      l4a.event("dialog-open", {dialog: "signup"});
    } else if (modal.id === "login-modal") {
      l4a.event("dialog-open", {dialog: "login"});
    }

    document.body.style.overflow = "hidden";
    document.getElementById("wrap").classList.add("shifted");
  }
  modal.focus();
}

export function hideModal() {
  var modal = document.querySelector(".modal-wrap.visible");
  if (!modal) return;
  if (modal.id === "signup-modal") {
    l4a.event("dialog-close", {dialog: "signup"});
  } else if (modal.id === "login-modal") {
    l4a.event("dialog-close", {dialog: "login"});
  }
  modal.classList.remove("visible");
  document.getElementById("wrap").classList.remove("shifted");
  document.body.style.overflow = "auto";
  setTimeout((_) => modal.classList.add("hidden"), 300);
}

function updateDropdownPos(dropdown, parent) {
  var sRect = parent.getBoundingClientRect(),
    tRect = dropdown.getBoundingClientRect(),
    origin,
    t,
    l;

  if (sRect.bottom + tRect.height > window.innerHeight) {
    origin = "bottom center";
    t = sRect.top - tRect.height;
  } else {
    origin = "top center";
    t = sRect.bottom;
  }

  l = sRect.left + (sRect.width - tRect.width) / 2;

  if (l < 0) {
    l = 0;
  } else if (l + tRect.width > window.innerWidth) {
    l = window.innerWidth - tRect.width;
  }

  dropdown.style.top = t + "px";
  dropdown.style.left = l + "px";
  dropdown.style.transformOrigin = origin;
}

export function showDropdown(dropdown, parent) {
  if (typeof dropdown == "string") dropdown = document.querySelector(dropdown);
  if (!dropdown) return;

  if (typeof parent == "string") parent = document.querySelector(parent);
  if (!parent) return;

  var hidingClass = "hidden",
    duration = 150,
    skipAnimation = false;

  if (dropdown.offsetWidth > 0 && dropdown.offsetHeight > 0) skipAnimation = true;
  dropdown.classList.remove(hidingClass);
  if (dropdown.offsetWidth == 0 || dropdown.offsetHeight == 0) skipAnimation = true;
  if (skipAnimation) return;

  if (getComputedStyle(dropdown).position != "fixed") {
    dropdown.style.setProperty("position", "fixed", "important");
  }

  if (dropdown.offsetWidth < parent.offsetWidth) {
    dropdown.style.width = parent.offsetWidth + "px";
  }

  dropdown.style.zIndex = "10000";
  updateDropdownPos(dropdown, parent);
  dropdown.style.transform = "translateY(-10px)";
  dropdown.style.opacity = "0";
  redraw(dropdown);
  dropdown.style.transition = "all " + duration + "ms ease-out";
  dropdown.style.transform = "translateY(0)";
  dropdown.style.opacity = "1";

  const updatePos = updateDropdownPos.bind(null, dropdown, parent);

  const hideDropdown = function () {
    dropdown.style.transition = "opacity " + duration + "ms ease";
    redraw(dropdown);
    dropdown.style.opacity = "0";

    setTimeout(function () {
      dropdown.classList.add(hidingClass);
    }, duration);

    document.body.removeEventListener("click", hideDropdown, true);
    window.removeEventListener("resize", updatePos);
    window.removeEventListener("scroll", updatePos, true);
  };

  document.body.addEventListener("click", hideDropdown, true);
  window.addEventListener("resize", updatePos);
  window.addEventListener("scroll", updatePos, true);
}

export function expandHeight(target, duration) {
  var start = {
    height: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginTop: "0",
    marginBottom: "0",
    borderTopWidth: "0",
    borderBottomWidth: "0",
    overflow: "hidden",
    opacity: "0",
    transition: "none",
  };
  for (var prop in start) target.style[prop] = "";
  redraw(target);
  var cs = getComputedStyle(target),
    end = {
      height: cs.height,
      paddingTop: cs.paddingTop,
      paddingBottom: cs.paddingBottom,
      marginTop: cs.marginTop,
      marginBottom: cs.marginBottom,
      borderTopWidth: cs.borderTopWidth,
      borderBottomWidth: cs.borderBottomWidth,
      opacity: "1",
      transition: "all " + duration + "ms ease-in-out",
    };
  for (var prop in start) target.style[prop] = start[prop];
  redraw(target);
  for (var prop in end) target.style[prop] = end[prop];
}

export function collapseHeight(target, duration) {
  var cs = getComputedStyle(target),
    start = {
      height: cs.height,
      paddingTop: cs.paddingTop,
      paddingBottom: cs.paddingBottom,
      marginTop: cs.marginTop,
      marginBottom: cs.marginBottom,
      borderTopWidth: cs.borderTopWidth,
      borderBottomWidth: cs.borderBottomWidth,
      overflow: "hidden",
      transition: "none",
      opacity: "1",
    },
    end = {
      height: "0",
      paddingTop: "0",
      paddingBottom: "0",
      marginTop: "0",
      marginBottom: "0",
      borderTopWidth: "0",
      borderBottomWidth: "0",
      opacity: "0",
      transition: "all " + duration + "ms ease-in-out",
    };

  for (var prop in start) target.style[prop] = start[prop];
  redraw(target);
  for (var prop in end) target.style[prop] = end[prop];
}
