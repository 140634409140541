var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "cookie-disclaimer" }, [
        _c("div", { staticClass: "flex-grow-1" }),
        _vm._v(" "),
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.t("/dialog/cookie-disclaimer")) },
        }),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn-default ml-1", on: { click: _vm.ok } },
          [_vm._v(_vm._s(_vm.t("/dialog/ok")))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex-grow-1" }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }