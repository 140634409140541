<template>
  <div :class="['header-clock-wrap', {active: active, collapsed: collapsed}]">
    <div class="header-clock" @click="click">
      {{ time }} (<span v-if="tzAbbrTitle" :title="tzAbbrTitle">{{ tzAbbr }}</span
      ><span v-else-if="tzAbbr">{{ tzAbbr }}</span
      >{{ tzAbbr ? " " : "" }}{{ tzOffset }})
    </div>
  </div>
</template>

<script>
import moment from "moment";
import tzAbbrs from "../tz-abbr.json";

const SCROLL_TRESHOLD = 32; //px

export default {
  data() {
    return {
      time: "",
      tzAbbr: "",
      tzAbbrTitle: "",
      tzOffset: "",
      interval: null,
      collapsed: false,
      lastScrollY: 0,
    };
  },
  created() {
    this.update();
    this.interval = setInterval(this.update, 1000);
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    window.removeEventListener("scroll", this.onScroll);
  },
  computed: {
    active() {
      return this.$route.path == "/my-account";
    },
  },
  methods: {
    update() {
      this.time = moment().format(this.$store.state.user.preferences.timeDisplay == 1 ? "HH:mm:ss" : "h:mm:ss a");
      this.tzAbbr = moment().format("zz");
      if (this.tzAbbr.charAt(0) == "+" || this.tzAbbr.charAt(0) == "-") {
        this.tzAbbr = "";
      } else {
        let key = moment().format("zzZZ");
        if (key in tzAbbrs) {
          this.tzAbbrTitle = tzAbbrs[key];
        } else {
          this.tzAbbrTitle = "";
        }
      }
      this.tzOffset = moment().format("Z");
    },
    click() {
      if (this.$store.state.user.id != 0) {
        if (this.$route.path != "/my-account") this.$router.push("/my-account");
      } else {
        this.$store.commit("notify", {
          type: "info",
          message: "timezone-change-info",
        });
      }
    },
    onScroll() {
      this.collapsed = this.lastScrollY - window.scrollY < 0 || window.scrollY >= SCROLL_TRESHOLD;
      this.lastScrollY = window.scrollY;
    },
  },
};
</script>
