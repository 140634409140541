import "regenerator-runtime/runtime"; // necessary for transpiling async/await statements into ES5
import Vue from "vue";
import VueFluentPlugin from "./utils/vue-fluent-plugin";
import Root from "./pages/Root.vue";
import {hidePreloader} from "./utils/preloader";
import {hideModal} from "./utils/ui";
import {TRIAL_PERIOD_LENGTH} from "./utils/helpers";
import "moment/locale/fi.js";
import {l4aBaseUrl} from "./config";
import { apiCall } from "./utils/api";
var Store, Router;

(async function main() {
  await loadAnalytics();
  Store = (await import("./utils/store")).default;
  Router = (await import("./utils/router")).default;
  await Store.dispatch("init");

  Vue.use(VueFluentPlugin);

  /**
   * Globally used options
   */
  Vue.use(function (Vue) {
    Vue.mixin({
      computed: {
        l4a() {
          return window.l4a;
        },
        hasPremium() {
          return this.$store.state.user.premiumAccount.expires * 1000 > Date.now();
        },
        isTrial() {
          return Date.now() / 1000 - this.$store.state.user.premiumAccount.trialStarted < TRIAL_PERIOD_LENGTH;
        },
        isPremiumEvent() {
          return !!this.$store.state.page.isPremiumEvent;
        },
        hasGrantedSubscription() {
          return this.$store.state.user.premiumAccount.subscriptionId < 0;
        },
      },
    });
  });

  let app = new Vue({
    router: Router,
    store: Store,
    render: (h) => h(Root),
  }).$mount();

  document.body.appendChild(app.$el);
  hidePreloader();
  Store.update("appLoadingDone", true);
})();

// Push the location into the router if clicked a link where the "href" attribute starts with "/"
window.addEventListener(
  "click",
  (event) => {
    let link = event.target;
    if (link.tagName == "A") {
      let href = link.getAttribute("href");
      if (href.charAt(0) == "/" && !link.hasAttribute("target") && href.substring(0, 9) != "/company/") {
        if (Router.history.current.path != href) Router.push(href);
        event.preventDefault();
        hideModal();
      }
    }
  },
  {capture: true},
);

// Load and config analytics
async function loadAnalytics() {
  return new Promise((resolve) => {
    let script = document.createElement("SCRIPT");
    script.src = `${l4aBaseUrl}/${btoa(location.origin)}/l4a.min.js`;
    document.head.appendChild(script);
    script.onload = () => {
      l4a.config({
        getUserConsent() {
          return localStorage.getItem("hasSeenCookieDisclaimer") != null;
        },
        getUserId() {
          return Store ? Store.state.user.id || "" : "";
        },
        onTrackingStart(trackingId) {
          apiCall("/user/set-tracking-id", trackingId);
        },
        onTrackingEnd() {
          apiCall("/user/unset-tracking-id");
        },
      });
      resolve();
    };
  });
}
