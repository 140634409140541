<template>
  <div class="modal-wrap hidden" id="signup-modal" tabindex="-1" @keydown.esc="cancel">
    {{ tt("/signup") }}
    <div class="modal-header">
      <button class="modal-back-btn" @click="cancel">
        <i class="i back-lg"></i>
      </button>
      <div class="modal-title">
        {{ t("title") }}
        <span
          style="font-size: medium"
          @click="
            hideModal();
            showModal('#login-modal');
          "
          v-html="t('title-addition')"
        ></span>
      </div>
      <button class="modal-btn" @click="cancel">
        <i class="i times-lg"></i>
      </button>
    </div>
    <div class="modal-body">
      <SignUpForm ref="signUpForm" />
    </div>
  </div>
</template>

<script>
import {hideModal, showModal} from "../../ui";
import {trialRequested} from "../../helpers.js";
import SignUpForm from "./SignUpForm.vue";

export default {
  components: {
    SignUpForm,
  },
  computed: {
    signupError() {
      for (let id in this.$store.state.notifications) {
        let n = this.$store.state.notifications[id];
        if (n.type == "signup-error") {
          return {id, ...n};
        }
      }
      return null;
    },
  },
  mounted() {
    if (this.signupError) this.$nextTick(() => showModal(this.$el));
  },
  methods: {
    showModal,
    hideModal,
    cancel() {
      this.showPasswordRequirements = false;
      if (this.signupError) {
        this.$store.commit("dismissNotification", this.signupError.id);
      }
      hideModal();
      trialRequested.set(false);
    },
  },
};
</script>
