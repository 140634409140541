var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.currentLang,
          expression: "currentLang",
        },
      ],
      staticClass: "language-switch",
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.currentLang = $event.target.multiple
            ? $$selectedVal
            : $$selectedVal[0]
        },
      },
    },
    _vm._l(_vm.supportedLanguages, function (lang) {
      return _c("option", { key: lang, domProps: { value: lang } }, [
        _vm._v("\n    " + _vm._s(lang.toUpperCase()) + "\n  "),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }