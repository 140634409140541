<template>
  <div class="modal-wrap hidden" id="login-modal" tabindex="-1" @keydown.esc="cancel">
    <div class="modal-header">
      <button class="modal-back-btn" @click="cancel">
        <i class="i back-lg"></i>
      </button>
      <div class="modal-title">
        {{ t("/login/title") }}
        <span
          style="font-size: medium"
          @click="
            hideModal();
            showModal('#signup-modal');
          "
          v-html="t('/login/title-addition')"
        ></span>
      </div>
      <button class="modal-btn" @click="cancel">
        <i class="i times-lg"></i>
      </button>
    </div>
    <div class="modal-body">
      <LoginForm ref="loginForm" />
    </div>
  </div>
</template>

<script>
import {hideModal, showModal} from "../../ui";
import {trialRequested} from "../../helpers.js";
import LoginForm from "./LoginForm.vue";

export default {
  components: {
    LoginForm,
  },
  computed: {
    hasError() {
      for (let id in this.$store.state.notifications) {
        let n = this.$store.state.notifications[id];
        if (n.type == "login-error") {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    if (this.hasError) this.$nextTick(() => showModal(this.$el));
  },
  methods: {
    showModal,
    hideModal,
    cancel() {
      this.$refs.loginForm.cancel();
      hideModal();
      trialRequested.set(false);
    },
  },
};
</script>
