/**
 * URLS of the instances
 */
const prod = "https://loop4.com";
const test = "https://app-test.loop4.com";
const dev = "https://app-dev.loop4.com";
const dev1 = "https://app-dev-1.loop4.com";

export const apiUrl = {
  [prod]: "https://api.loop4.com",
  [test]: "https://api-test.loop4.com",
  [dev]: "https://api-dev.loop4.com",
  [dev1]: "https://api-dev-1.loop4.com",
}[location.origin];

export const supportedLanguages = ["en", "fi"];

export const loop4FreePrice = "0";
export const loop4PremiumPrice = "4.99";

export const l4aBaseUrl = {
  [prod]: "https://analytics.loop4.com",
  [test]: "https://analytics-dev.loop4.com",
  [dev]: "https://analytics-dev.loop4.com",
  [dev1]: "https://analytics-dev.loop4.com",
}[location.origin];
