<template>
  <div class="row gutt-2 gutt-md-2">
    <div class="col-12 col-md-6">
      <div class="row gutt-2">
        <div class="col-12">
          <button class="btn-default p-1" @click="authWithGoogle">
            <i class="i google"></i>
            {{ t("/login/login-with-google") }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <form @submit.prevent="signupWithEmail">
        <div class="row gutt-2">
          <div class="col-12" v-show="showPasswordRequirements">
            <div class="alert-info">
              <button type="button" class="btn-dismiss" @click="showPasswordRequirements = false">
                <i class="fa fa-times"></i>
              </button>
              {{ t("/forms/password-requirements") }}
            </div>
          </div>
          <div class="col-12">
            <input
              type="text"
              :class="['text-field', {'is-wrong': name.error && name.showError}]"
              :placeholder="t('/forms/name')"
              v-model.trim="name.value"
              @input="name.showError = false"
            />
            <div class="form-error">
              {{ name.error }}
            </div>
          </div>
          <div class="col-12">
            <input
              type="text"
              :class="['text-field', {'is-wrong': email.error && email.showError}]"
              :placeholder="t('/forms/email')"
              v-model.trim="email.value"
              @input="email.showError = false"
            />
            <div class="form-error">
              {{ email.error }}
            </div>
          </div>
          <div class="col-12">
            <input
              type="password"
              :class="['text-field', {'is-wrong': password.error && password.showError}]"
              :placeholder="t('/forms/password')"
              v-model="password.value"
              @input="password.showError = passwordConfirm.showError = false"
            />
            <div class="form-error">
              {{ password.error }}
            </div>
          </div>
          <div class="col-12">
            <input
              type="password"
              :class="[
                'text-field',
                {
                  'is-wrong': passwordConfirm.error && passwordConfirm.showError,
                },
              ]"
              :placeholder="t('/forms/password-confirm')"
              v-model="passwordConfirm.value"
              @input="passwordConfirm.showError = false"
            />
            <div class="form-error">
              {{ passwordConfirm.error }}
            </div>
          </div>
          <div class="col-12">
            <label :class="['checkbox', {'is-wrong': iAgree.error && iAgree.showError}]">
              <input type="checkbox" v-model="iAgree.value" @change="iAgree.showError = false" />
              <span></span>
              <span v-html="t('/forms/i-agree')"></span>
            </label>
            <div class="form-error">
              {{ iAgree.error }}
            </div>
          </div>
          <div class="col-12">
            <div class="row gutt-2">
              <div class="col l">
                <button type="submit" :class="['btn-primary', {busy: busy}]">
                  {{ t("/signup/submit") }}
                </button>
              </div>
              <div class="col r">
                <button type="button" class="btn-default" @click="cancel">
                  {{ t("/forms/cancel") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {apiCall} from "../../api";
import {hideModal} from "../../ui";
import {trialRequested} from "../../helpers.js";
import isEmail from "validator/lib/isEmail";
import {getDefaultPreferences, isJust, just, isStrongPassword} from "../../helpers";

export default {
  data() {
    return {
      inputs: ["name", "email", "password", "passwordConfirm", "iAgree"],
      busy: false,
      showPasswordRequirements: false,
      name: {
        value: "",
        error: "",
        showError: false,
        validators: {
          "/forms/name-empty": (_) => this.name.value == "",
        },
      },
      email: {
        value: "",
        error: "",
        showError: false,
        validators: {
          "/forms/email-empty": (_) => this.email.value == "",
          "/forms/email-malformed": (_) => !isEmail(this.email.value),
        },
      },
      password: {
        value: "",
        error: "",
        showError: false,
        validators: {
          "/forms/password-empty": (_) => this.password.value == "",
          "/forms/password-weak": (_) => !isStrongPassword(this.password.value),
        },
      },
      passwordConfirm: {
        value: "",
        error: "",
        showError: false,
        validators: {
          "/forms/password-confirm-empty": (_) => this.passwordConfirm.value == "",
          "/forms/password-confirm-mismatch": (_) => this.passwordConfirm.value != this.password.value,
        },
      },
      iAgree: {
        value: false,
        error: "",
        showError: false,
        validators: {
          "/forms/i-agree-empty": (_) => this.iAgree.value == false,
        },
      },
    };
  },
  methods: {
    async redirectUrl() {
      let countobj = await apiCall("/event/countOfAll"),
        redirect = "/events";
      if (countobj.json.active + countobj.json.drafts + countobj.json.archived == 0 && countobj.json.joined > 0) {
        redirect = "/events/joined";
      }
      return redirect;
    },

    async authWithGoogle() {
      let successRedirectUrl =
        window.location.pathname == "/" ? window.location.origin + (await this.redirectUrl()) : window.location.href;
      let response = await apiCall("/services/google/auth", {
        successUrl: successRedirectUrl,
        failureUrl: window.location.href,
        preferences: this.$store.state.user.preferences,
        requestAccessToCalendar: false,
      });
      if (response.ok) {
        l4a.event("google-auth-start");
        this.$emit("redirect");
        window.location.href = response.json;
      } else {
        this.$store.commit("notify", {
          type: "danger",
          message: "unexpected-error",
        });
      }
    },

    async signupWithEmail() {
      if (this.busy) return;

      let anyError = false;
      this.inputs.forEach((name) => {
        let input = this[name];
        for (let errCode in input.validators) {
          let isInvalid = input.validators[errCode];
          if (isInvalid()) {
            input.error = this.t(errCode);
            input.showError = true;
            anyError = true;
            break;
          }
        }
      });

      if (!isStrongPassword(this.password.value)) {
        this.showPasswordRequirements = true;
      }

      if (anyError) return;

      this.busy = true;
      localStorage.removeItem("preferences");
      let response = await apiCall("/user/signup", {
        name: this.name.value,
        email: this.email.value,
        password: this.password.value,
        preferences: getDefaultPreferences(),
      });
      this.busy = false;

      if (response.ok && isJust(response.json)) {
        l4a.event("email-registration-success");
        this.$store.update("user", just(response.json));
        if (trialRequested.get()) {
          //then we have a request to redirect to Payment operator
          hideModal();
          this.$emit("redirect");
          window.location.reload(); //need to reload since need to initiate /Root.vue that will take care of further steps
        } else {
          this.$store.commit("notify", {
            type: "success",
            message: "/notifications/signup-success",
          });
          hideModal();
          if (this.$route.path == "/") this.redirectUrl().then((redirect) => this.$router.push(redirect));
        }
      } else if (response.httpStatus == 400 && response.json.message === "already-registered") {
        l4a.event("email-registration-fail", {reason: "already-registered"});
        this.email.error = this.t("/forms/email-already-used");
        this.email.showError = true;
      } else {
        l4a.event("email-registration-fail", {reason: "unknown"});
        this.$store.commit("notify", {
          type: "danger",
          message: "unexpected-error",
        });
      }
    },
    cancel() {
      this.showPasswordRequirements = false;
      hideModal();
      trialRequested.set(false);
    },
  },
};
</script>
