var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("Header"),
      _vm._v(" "),
      _vm.error404
        ? _c("StaticPage")
        : _vm.error403
        ? _c("Unauthorized")
        : _vm.routingDone
        ? _c("RouterView")
        : _vm._e(),
      _vm._v(" "),
      _c("Footer"),
      _vm._v(" "),
      _c("Notifications"),
      _vm._v(" "),
      !_vm.authorized
        ? [_c("Signup"), _vm._v(" "), !_vm.error403 ? _c("Login") : _vm._e()]
        : _vm._e(),
      _vm._v(" "),
      !_vm.error404 && !_vm.error403 && _vm.isEventEditor
        ? [_c("TimeSettings"), _vm._v(" "), _c("SpecialInviteeForm")]
        : _vm._e(),
      _vm._v(" "),
      _vm.showRedirectMessage ? _c("Redirect") : _vm._e(),
      _vm._v(" "),
      _c("CookieDisclaimer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }