<template>
  <header id="header">
    <nav class="navbar container">
      <RouterLink v-if="hasPremium" class="i logo-premium" to="/" />
      <RouterLink v-else class="i logo" to="/" />
      <div class="top-menu">
        <HeaderClock />
        <ul class="links" v-if="!authorized">
          {{
            tt("/menu")
          }}
          <li>
            <RouterLink to="/search" exact>
              <i class="fa fa-search hidden-md"></i>
              <span class="hidden-sm-down">
                {{ t("search") }}
              </span>
            </RouterLink>
          </li>
          <li v-if="!$store.state.error403">
            <div @click="showModal('#login-modal')">
              <i class="fa fa-sign-in-alt hidden-md"></i>
              <span class="hidden-sm-down">{{ t("log-in") }}</span>
            </div>
          </li>
          <li>
            <div @click="showModal('#signup-modal')">
              <i class="fa fa-user-plus hidden-md"></i>
              <span class="hidden-sm-down">{{ t("sign-up") }}</span>
            </div>
          </li>
          <li>
            <div>
              <LanguageSwitch />
            </div>
          </li>
        </ul>
        <ul class="links" v-else>
          {{
            tt("/menu")
          }}
          <li>
            <RouterLink to="/search" exact>
              <i class="fa fa-search hidden-md"></i>
              <span class="hidden-sm-down">
                {{ t("search") }}
              </span>
            </RouterLink>
          </li>
          <li>
            <RouterLink to="/event/new">
              <i class="fa fa-calendar-plus hidden-md"></i>
              <span class="hidden-sm-down">
                {{ t("add-event") }}
              </span>
            </RouterLink>
          </li>
          <li>
            <RouterLink to="/events">
              <i class="fa fa-calendar-alt hidden-md"></i>
              <span class="hidden-sm-down">
                {{ t("my-events") }}
              </span>
            </RouterLink>
          </li>
          <li>
            <div>
              <LanguageSwitch />
            </div>
          </li>
          <li>
            <div @click="showDropdown">
              <i class="fa fa-user-circle"></i>
              <span class="hidden-md-down">{{ accountName }}</span>
              <i class="fa fa-angle-down hidden-md-down"></i>
            </div>
            <ul class="dropdown-menu hidden">
              <li class="hidden-md-down">
                <div class="read-only">
                  <span>{{ accountEmail }}</span>
                </div>
              </li>
              <li class="separator hidden-md-down"></li>
              <li>
                <RouterLink to="/my-account">
                  <i class="fa fa-cogs"></i>
                  {{ t("my-account") }}
                </RouterLink>
              </li>
              <li class="separator"></li>
              <li>
                <div @click="logout">
                  <i class="fa fa-sign-out-alt"></i>
                  {{ t("log-out") }}
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import {apiCall} from "../api";
import {showModal, showDropdown} from "../ui";
import LanguageSwitch from "./LanguageSwitch.vue";
import HeaderClock from "./HeaderClock.vue";

export default {
  components: {LanguageSwitch, HeaderClock},
  computed: {
    authorized() {
      return this.$store.state.user.id != 0;
    },
    accountName() {
      let user = this.$store.state.user;
      if (user.name != "") {
        return user.name;
      } else {
        return user.email;
      }
    },
    accountEmail() {
      return this.$store.state.user.email;
    },
  },
  methods: {
    showModal,

    showDropdown() {
      let dropdown = this.$el.querySelector("ul.dropdown-menu"),
        parent = dropdown.previousElementSibling;
      showDropdown(dropdown, parent);
    },

    async logout() {
      let response = await apiCall("/user/logout");
      if (response.ok && response.json === true) {
        l4a.event("logout");
        this.$store.commit("logout");
        if (this.$route.path != "/") this.$router.push("/");
      } else {
        this.$store.commit("notify", {
          type: "danger",
          message: "unexpected-error",
        });
      }
    },
  },
};
</script>
